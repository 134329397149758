.ButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 40px;
}
.ButtonContainer a {
  width: 200px;
}
@media (max-width: 600px) {
  .ButtonContainer {
    padding: 24px 0;
  }
  .ButtonContainer a {
    width: 100%;
  }
}

/* Only keep minimal radio button alignment fix */
:global .MuiFormGroup-root {
  flex-direction: row !important;
}
